import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import AppManager from "services/app-manager"
import Layout from "components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "components/typography"
import { ListHeaderProvider } from "components/content/list-header"
import Faq from "components/content/faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Markdown from "components/markdown"
import ReturnLinkChain from "components/content/return-link-chain"

const BuyNowPayLaterView = () => {
  return <div></div>
}

const BuyNowPayLater = () => {
  const isWide = useMediaQuery("(min-width:768px)")

  const md = `### Buy Now Pay Later (BNPL) Overview

  Buy Now Pay Later (BNPL) has gained immense popularity, providing consumers with a convenient and flexible way to make purchases. With BNPL, consumers can buy products or services and spread the payment over time without incurring any interest or upfront fees. Major BNPL providers in Australia include Afterpay, Zip, and Klarna, among others. These platforms typically integrate with online and in-store retailers, allowing users to make purchases and choose the BNPL option at the checkout. Each provider offers slightly different terms, such as the number of instalments and repayment schedules. It's important for consumers to compare options and consider factors like customer reviews, merchant acceptance, and any potential late fees. Responsible use of BNPL can be a useful tool for budgeting and managing expenses, but it's crucial to be mindful of repayment obligations to avoid accumulating unnecessary debt.
  
  ### Afterpay
  
  Afterpay is the most popular Buy Now Pay Later (BNPL) service in Australia. It allows consumers to make purchases and pay for them in instalments, without any interest or fees if payments are made on time. With Afterpay, users can shop online or in-store and select Afterpay as the payment method at checkout. Afterpay splits the total cost into four equal payments, which are automatically deducted from the user's linked debit or credit card every two weeks. Afterpay is well-suited for consumers who want the convenience of spreading out their payments without the burden of interest charges. It can be especially useful for smaller to medium-sized purchases, providing financial flexibility while keeping costs manageable. However, it's important to carefully monitor payments and ensure timely repayments to avoid late fees and potential impact on credit scores.
  
  ### Zip Pay and Zip Money
  
  Zip Pay and Zip Money are popular Buy Now Pay Later (BNPL) services in Australia offered by Zip Co. While they share some similarities, there are key differences between the two that cater to different consumer needs.
  
  Zip Pay is a BNPL service that allows users to make purchases and pay them off in flexible installments. It provides a credit line where users can make multiple purchases up to their approved limit without needing to reapply each time. Zip Pay offers interest-free periods, and users have the option to pay off their balance in full or make minimum monthly repayments. It is well-suited for smaller purchases and provides financial flexibility, allowing users to spread out payments over time without incurring interest charges. However, it's important to make timely repayments and be mindful of any applicable fees.
  
  On the other hand, Zip Money is a BNPL service that offers a line of credit for larger purchases. It provides a higher credit limit compared to Zip Pay and is more suitable for bigger-ticket items. Zip Money offers interest-free periods, and users have the option to make minimum monthly repayments or pay off the balance in full. However, unlike Zip Pay, Zip Money charges interest beyond the interest-free period. It is important for users to carefully manage repayments and be aware of the interest charges to avoid accumulating unnecessary debt.
  
  ### Klarna
  
  Klarna is a popular international Buy Now Pay Later (BNPL) service that offers Australian consumers the convenience of making purchases and paying for them over time. Klarna allows users to split their payments into instalments or defer them to a later date, providing flexibility in managing their finances. While not as widely available in Australia as other BNPL providers, Klarna has partnered with several Australian retailers, making it accessible to some consumers. Whether Klarna suits an individual's needs depends on factors such as its acceptance by their preferred merchants and the terms and conditions offered. It's advisable for Australian consumers to consider the availability, reputation, and compatibility of Klarna with their purchasing habits to determine if it aligns with their requirements.
  
  ### Humm
  
  Humm is a Buy Now Pay Later (BNPL) service that allows consumers to make purchases and spread the cost over time. Formerly known as Certegy Ezi-Pay and Oxipay, Humm offers interest-free instalment plans and flexible payment options. Users can shop online or in-store and select Humm as the payment method at checkout. Humm provides different payment terms and repayment options, allowing customers to choose a plan that suits their budget and financial situation. Whether Humm suits an individual's needs depends on factors such as its acceptance by their preferred retailers, the availability of interest-free periods, and any applicable fees or terms specific to each transaction. It's recommended for consumers to review the features and conditions of Humm, compare it to other BNPL options, and consider their own financial circumstances to determine if it aligns with their needs.
  
  ### CommBank StepPay
  
  CommBank StepPay is a Buy Now Pay Later (BNPL) service offered by Commonwealth Bank, one of Australia's largest financial institutions. StepPay allows Australian consumers to make purchases and pay for them in interest-free instalments. Users can link their StepPay account to their Commonwealth Bank transaction account and choose the StepPay option at participating merchants. It provides a flexible payment option for managing expenses over time. Whether StepPay suits an individual's needs depends on factors such as its acceptance by their preferred retailers, compatibility with their Commonwealth Bank accounts, and the terms and conditions offered. It's recommended for Australian consumers to evaluate the features, fees, and convenience of StepPay in comparison to other BNPL services to determine if it meets their specific requirements.
  
  ### NAB Now Pay Later
  
  NAB Now Pay Later is a buy now pay later service offered by NAB. It allows customers to split their purchases into four equal repayments, with the first repayment due upfront and the remaining three due fortnightly. There are no interest or fees associated with NAB Now Pay Later, making it a convenient and affordable way to spread the cost of larger purchases.
  
  NAB Now Pay Later may be a good option for consumers who are looking for a way to improve their cash flow or who want to avoid paying interest on their purchases. However, it is important to note that NAB Now Pay Later is a form of credit, and as such, it should be used responsibly. Consumers should only use NAB Now Pay Later for purchases that they can afford to repay, and they should make sure that they can make the repayments on time.`

  return (
    <Layout title={`Compare Afterpay, Zip and other leading BNPL options | Stay or Go`} description={"Compare BNPL offers from Afterpay, Zip, the major banks and other popular brands."} image="/video/StayOrGo_Animation_01.jpf" urlSlug="buy-now-pay-later">
      {/* TODO: implement Buy Now Pay Later category page */}
      {/* <div className="container-center">
        <div className="home-container">
          <BuyNowPayLaterView />
        </div>
      </div> */}
      <div className="page-container">
        <div
          style={
            isWide
              ? {
                  maxWidth: "960px",
                  width: "81.25%",
                  marginBottom: "100px",
                }
              : {
                  maxWidth: "90%",
                  width: "81.25%",
                  margin: "auto",
                }
          }
        >
          <ReturnLinkChain linkChain={[]} currentLocationItem={{ name: "Buy Now Pay Later" }} />
          <div>
            <Typography.H1>Compare Afterpay, Zip and other leading BNPL options</Typography.H1>
            <Typography.P>Compare BNPL offers from Afterpay, Zip, the major banks and other popular brands.</Typography.P>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <LazyLoadImage src="/images/buy_now_pay_later/bnpl_3x4.jpg" style={{ width: "100%", marginTop: "30px", maxWidth: "648px" }} alt="" />
            </span>
            <Markdown md={md} isArticle={true} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BuyNowPayLater
